<template>
  <div class="channel">
    <BannerImg :img-url="$StaticFileUrl + '/mp/BannerImage/banner3_img.jpg'">
      <div class="title wow fadeInDown" data-wow-duration="1s">万鲸合作伙伴全国招商中</div>
      <div class="desc wow fadeInDown" data-wow-duration="1s">支持H5、APP、小程序等多种形式的渠道合作</div>
      <div class="flex-row flex-col-center">
        <div class="btn-red wow flipInX" data-wow-duration="1s" style="margin-top: 100px">我要代理</div>
      </div>
    </BannerImg>
    <ChannelPart1></ChannelPart1>
    <ChannelPart2></ChannelPart2>
    <ChannelPart3></ChannelPart3>
    <ChannelPart4></ChannelPart4>
    <ChannelPart5></ChannelPart5>
    <ChannelPart6></ChannelPart6>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/28 17:38
 * @version 1.0
 * @description
 */
import BannerImg from '@/components/BannerImg'
import ChannelPart1 from '@/views/Channel/ChannelPart1'
import ChannelPart2 from '@/views/Channel/ChannelPart2'
import ChannelPart3 from '@/views/Channel/ChannelPart3'
import ChannelPart4 from '@/views/Channel/ChannelPart4'
import ChannelPart6 from '@/views/Channel/ChannelPart6'
import ChannelPart5 from '@/views/Channel/ChannelPart5'
export default {
  name: 'Channel',
  components: {
    ChannelPart5,
    ChannelPart6,
    ChannelPart4,
    ChannelPart3,
    ChannelPart2,
    ChannelPart1,
    BannerImg,
  },
}
</script>

<style scoped>

</style>
