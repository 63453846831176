var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ChannelPart2"},[_vm._m(0),_c('div',{staticClass:"ensure_content"},[_c('div',{staticClass:"bg1",style:({
        backgroundImage: `url(${
          _vm.$StaticFileUrl + '/mp/pages/Channel/frcl_img_001.png'
        })`,
      })}),_c('div',{staticClass:"bg2",style:({
        backgroundImage: `url(${
          _vm.$StaticFileUrl + '/mp/pages/Channel/frcl_img_001.png'
        })`,
      })}),_c('div',{staticClass:"ensure_left"},_vm._l((_vm.ChannelPart2_Data),function(item,index){return _c('div',{key:index,class:[
          'ensure_li',
          'wow',
          { animate__fadeInLeft: index === 0 },
          { animate__fadeInRight: index !== 0 },
        ],attrs:{"data-wow-duration":"1s"},on:{"mouseenter":function($event){_vm.activeKey = index}}},[_c('div',{class:['ensure_li_position', { active: _vm.activeKey === index }]},[_c('img',{attrs:{"src":item.icon,"alt":""}}),_c('div',{staticClass:"ensure_headline"},[_vm._v("高比率分佣分成")]),_c('span',{staticClass:"bar"})])])}),0),_c('div',{staticClass:"ensure_right wow animate__fadeInUp",attrs:{"data-wow-duration":"1s"}},_vm._l((_vm.ChannelPart2_Data),function(item,index){return _c('img',{key:index,class:{ active: _vm.activeKey === index },attrs:{"src":item.imgUrl}})}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ensure_block wow animate__slideInUp",attrs:{"data-wow-duration":"1s"}},[_c('div',{staticClass:"block_maintitle"},[_vm._v("分润策略")])])
}]

export { render, staticRenderFns }