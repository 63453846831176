<template>
  <div class="ChannelPart5">
    <div class="map_hidline">
      <div>遍布全国的渠道伙伴提供本地化的服务</div>
    </div>
    <div class="map_title">
      <div class="map_title_first">
        <div class="map_title_number">500+</div>
        <div class="map_title_string">渠道伙伴</div>
      </div>
      <div class="map_title_last">
        <div class="map_title_number">7*24h</div>
        <div class="map_title_string">优质服务</div>
      </div>
    </div>
    <div class="map_content">
      <div class="map_content_img">
        <img
          class="mapChina"
          :src="$StaticFileUrl + '/mp/pages/Channel/map_01.png'"
          alt=""
        />
        <div class="map_content_box">
          <div
            v-for="(item, index) in ChannelPart5_Data"
            :key="index"
            class="map_content_boxBj"
          >
            <img
              @mouseleave="activeKey = ''"
              :src="item.imgUrl"
              :class="{ display_block: activeKey === index }"
              alt=""
            />
            <img
              @mouseenter="activeKey = index"
              :class="['isc', { opacity_none: activeKey === index }]"
              :src="$StaticFileUrl + '/mp/pages/Channel/map_isc.png'"
              alt=""
            />
            <div
              :style="{
                backgroundImage: `url(${
                  $StaticFileUrl + '/mp/pages/Channel/map_bj.png'
                })`,
              }"
              :class="[
                'map_content_small',
                { opacity_block: activeKey === index },
              ]"
            >
              <div>{{ item.name }}</div>
              <span>{{ item.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChannelPart5_Data from "@/views/Channel/Data/ChannelPart5_Data";

/**
 * @author  XuHongli
 * @date  2022/12/30 9:42
 * @version 1.0
 * @description
 */
export default {
  name: "ChannelPart5",
  created() {
    console.log(this.ChannelPart5_Data.length);
  },
  data() {
    return {
      ChannelPart5_Data,
      activeKey: "",
    };
  },
};
</script>

<style scoped lang="scss">
.ChannelPart5 {
  position: relative;
  height: 707px;
  width: 100%;
  margin-top: 50px;
}
.map_title {
  .map_title_first {
    position: absolute;
    top: 193px;
    left: 232px;
    z-index: 5;
  }
  .map_title_last {
    position: absolute;
    top: 311px;
    left: 232px;
  }
  .map_title_number {
    font-size: 32px;
    font-weight: 500;
    color: #ff6a00;
    line-height: 32px;
  }
  .map_title_string {
    font-size: 18px;
    font-weight: 400;
    color: #222;
    line-height: 18px;
    margin-top: 5px;
  }
}
.map_hidline {
  position: absolute;
  top: 43px;
  left: 50%;
  z-index: 999;
  transform: translateX(-50%);
  font-size: 40px;
  font-weight: 400;
  color: #222;
  line-height: 47px;
}
.map_content {
  width: 100%;
  height: 100%;
  .map_content_img {
    position: relative;
    width: 1639px;
    height: 100%;
    margin: 0 auto;
    .mapChina {
      width: 100%;
      height: 100%;
    }
  }
}
.map_content_box {
  .map_content_small {
    width: 85px;
    height: 40px;
    font-size: 10px;
    font-weight: 400;
    color: #fff;
    line-height: 12px;
    transform: scale(0.83333);
    transform-origin: 0 0;
    padding: 3px;
  }
  .map_content_boxBj {
    position: absolute;
    z-index: 1;
    transition: all 1s;
    img {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 1s;
    }
    .isc {
      position: absolute;
      z-index: 5;
      width: 18px;
      height: 18px;
      cursor: pointer;
      opacity: 1;
    }
    .display_block {
      transform: translateY(-10px);
      opacity: 1;
    }
    .opacity_block {
      transform: translateY(-20px);
      display: block !important;
    }
    .opacity_none {
      display: none;
    }
    .map_content_small {
      position: relative;
      z-index: 3;
      display: none;
      transition: all 1s;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
    &:first-child {
      width: 78px;
      height: 64px;
      top: 294px;
      right: 632px;
      .isc {
        top: 8px;
        left: 42px;
      }
      .map_content_small {
        top: -15px;
        left: 4px;
      }
    }
    &:nth-child(2) {
      width: 26px;
      height: 22px;
      top: 324px;
      right: 718px;
      .isc {
        top: -9px;
        left: 3px;
      }
      .map_content_small {
        top: -32px;
        left: -38px;
      }
    }
    &:nth-child(3) {
      width: 20px;
      height: 25px;
      top: 336px;
      right: 717px;
      .isc {
        top: -5px;
        left: 1px;
      }
      .map_content_small {
        top: -27px;
        left: -35px;
      }
    }
    &:nth-child(4) {
      width: 20px;
      height: 25px;
      top: 336px;
      right: 717px;
      .isc {
        top: 33px;
        left: 11px;
      }
      .map_content_small {
        top: -10px;
        left: 6px;
      }
    }
    &:nth-child(5) {
      width: 50px;
      height: 76px;
      top: 328px;
      right: 753px;
      .isc {
        top: 21px;
        left: 15px;
      }
      .map_content_small {
        top: -3px;
        left: -18px;
      }
    }
    &:nth-child(6) {
      width: 87px;
      height: 53px;
      top: 359px;
      right: 665px;
      .isc {
        top: 4px;
        left: 22px;
      }
      .map_content_small {
        top: -21px;
        left: -9px;
      }
    }
    &:nth-child(7) {
      width: 62px;
      height: 97px;
      top: 343px;
      right: 792px;
      .isc {
        top: 43px;
        left: 27px;
      }
      .map_content_small {
        top: 17px;
        left: -4px;
      }
    }
    &:nth-child(8) {
      width: 67px;
      height: 65px;
      top: 380px;
      right: 733px;
      .isc {
        top: 15px;
        left: 28px;
      }
      .map_content_small {
        top: -13px;
        left: 0;
      }
    }
    &:nth-child(9) {
      width: 62px;
      height: 57px;
      top: 396px;
      right: 673px;
      .isc {
        top: 4px;
        left: 26px;
      }
      .map_content_small {
        top: -22px;
        left: -4px;
      }
    }
    &:nth-child(10) {
      width: 122px;
      height: 104px;
      top: 407px;
      right: 817px;
      .isc {
        top: 26px;
        left: 53px;
      }
      .map_content_small {
        top: 5px;
        left: 22px;
      }
    }
    &:nth-child(11) {
      width: 56px;
      height: 55px;
      top: 429px;
      right: 800px;
      .isc {
        top: 15px;
        left: 12px;
      }
      .map_content_small {
        top: -12px;
        left: -19px;
      }
    }
    &:nth-child(12) {
      width: 85px;
      height: 88px;
      top: 415px;
      right: 738px;
      .isc {
        top: 18px;
        left: 38px;
      }
      .map_content_small {
        top: -10px;
        left: 7px;
      }
    }
    &:nth-child(13) {
      width: 56px;
      height: 66px;
      top: 401px;
      right: 697px;
      .isc {
        top: 25px;
        left: 18px;
      }
      .map_content_small {
        top: -1px;
        left: -13px;
      }
    }
    &:nth-child(14) {
      width: 12px;
      height: 18px;
      top: 436px;
      right: 672px;
      .isc {
        top: -8px;
        left: -1px;
      }
      .map_content_small {
        top: -35px;
        left: -32px;
      }
    }
    &:nth-child(15) {
      width: 65px;
      height: 60px;
      top: 467px;
      right: 809px;
      .isc {
        top: 9px;
        left: 35px;
      }
      .map_content_small {
        top: -18px;
        left: 4px;
      }
    }
    &:nth-child(16) {
      width: 58px;
      height: 71px;
      top: 455px;
      right: 759px;
      .isc {
        top: 18px;
        left: 25px;
      }
      .map_content_small {
        top: -8px;
        left: -6px;
      }
    }
    &:nth-child(17) {
      width: 53px;
      height: 71px;
      top: 457px;
      right: 712px;
      .isc {
        top: 18px;
        left: 13px;
      }
      .map_content_small {
        top: -20px;
        left: -17px;
      }
    }
    &:nth-child(18) {
      width: 56px;
      height: 63px;
      top: 436px;
      right: 662px;
      .isc {
        top: 18px;
        left: 18px;
      }
      .map_content_small {
        top: -22px;
        left: -12px;
      }
    }
    &:nth-child(19) {
      width: 82px;
      height: 67px;
      top: 503px;
      right: 783px;
      .isc {
        top: 21px;
        left: 38px;
      }
      .map_content_small {
        top: -9px;
        left: 7px;
      }
    }
    &:nth-child(20) {
      width: 86px;
      height: 70px;
      top: 511px;
      right: 723px;
      .isc {
        top: 19px;
        left: 35px;
      }
      .map_content_small {
        top: -9px;
        left: 4px;
      }
    }
    &:nth-child(21) {
      width: 53px;
      height: 64px;
      top: 477px;
      right: 688px;
      .isc {
        top: 18px;
        left: 18px;
      }
      .map_content_small {
        top: -9px;
        left: -18px;
      }
    }
    &:nth-child(22) {
      width: 31px;
      height: 32px;
      top: 576px;
      right: 791px;
      .isc {
        top: 0;
        left: 7px;
      }
      .map_content_small {
        top: -29px;
        left: -23px;
      }
    }
  }
}
</style>
