/**
 * @author  XuHongli
 * @date  2022/12/29 16:19
 * @version 1.0
 * @description
 */

const ChannelPart1_Data = [
  {
    title: '企业选择',
    desc: '10万+'
  },
  {
    title: '供应链商品',
    desc: '300万+'
  },
  {
    title: '专属服务',
    desc: '7X24小时'
  },
]

export default ChannelPart1_Data
