<template>
  <div class="ChannelPart3">
    <div class="material_block wow animate__zoomIn" data-wow-duration="1s">
      <div class="block_maintitle">扶持政策</div>
    </div>
    <!-- <img class="rightImg" style="right: 0;top: -19%;" v-lazy="$StaticFileUrl + '/mp/img/brief_bag.png'" alt=""/>
    <img class="rightImg" style="left: 0;top: 33%;" v-lazy="$StaticFileUrl + '/mp/img/blief_bag.png'" alt=""/> -->
    <div class="material_content">
      <div
        v-for="(item, index) in ChannelPart3_Data"
        :key="index"
        class="materialn_li wow animate__bounceInUp"
        data-wow-duration="1s"
      >
        <div class="material_img_nav">
          <img :src="item.icon" alt="" />
        </div>
        <div>
          <div class="material_headline">
            {{ item.title }}
          </div>
          <div class="material_subtitle">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from "wowjs";
import ChannelPart3_Data from "@/views/Channel/Data/ChannelPart3_Data";

/**
 * @author  XuHongli
 * @date  2022/12/29 17:52
 * @version 1.0
 * @description
 */
export default {
  name: "ChannelPart3",
  data() {
    return {
      ChannelPart3_Data,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.ChannelPart3 {
  width: 100%;
  padding: 80px 0 20px;
  position: relative;
  background: #fff;
  .material_block {
    text-align: center;
    margin-bottom: 88px;
  }
}
.material_content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 1280px;
  margin: auto;
  .materialn_li {
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 110px;
    .material_img_nav {
      height: 70px;
      img {
        display: inline-block;
        width: 70px;
        height: 70px;
      }
    }
    > div:last-child {
      margin-left: 32px;
      .material_headline {
        font-size: 22px;
        font-weight: 700;
        color: #222;
      }
      .material_subtitle {
        width: 326px;
        font-size: 18px;
        font-weight: 400;
        color: #666;
        line-height: 18px;
        margin-top: 10px;
      }
    }
  }
}
</style>
