var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ChannelPart5"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"map_content"},[_c('div',{staticClass:"map_content_img"},[_c('img',{staticClass:"mapChina",attrs:{"src":_vm.$StaticFileUrl + '/mp/pages/Channel/map_01.png',"alt":""}}),_c('div',{staticClass:"map_content_box"},_vm._l((_vm.ChannelPart5_Data),function(item,index){return _c('div',{key:index,staticClass:"map_content_boxBj"},[_c('img',{class:{ display_block: _vm.activeKey === index },attrs:{"src":item.imgUrl,"alt":""},on:{"mouseleave":function($event){_vm.activeKey = ''}}}),_c('img',{class:['isc', { opacity_none: _vm.activeKey === index }],attrs:{"src":_vm.$StaticFileUrl + '/mp/pages/Channel/map_isc.png',"alt":""},on:{"mouseenter":function($event){_vm.activeKey = index}}}),_c('div',{class:[
              'map_content_small',
              { opacity_block: _vm.activeKey === index },
            ],style:({
              backgroundImage: `url(${
                _vm.$StaticFileUrl + '/mp/pages/Channel/map_bj.png'
              })`,
            })},[_c('div',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(item.text))])])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map_hidline"},[_c('div',[_vm._v("遍布全国的渠道伙伴提供本地化的服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map_title"},[_c('div',{staticClass:"map_title_first"},[_c('div',{staticClass:"map_title_number"},[_vm._v("500+")]),_c('div',{staticClass:"map_title_string"},[_vm._v("渠道伙伴")])]),_c('div',{staticClass:"map_title_last"},[_c('div',{staticClass:"map_title_number"},[_vm._v("7*24h")]),_c('div',{staticClass:"map_title_string"},[_vm._v("优质服务")])])])
}]

export { render, staticRenderFns }