/**
 * @author  XuHongli
 * @date  2022/12/30 10:15
 * @version 1.0
 * @description
 */
const ChannelPart5_Data = [
  {
    name: '辽宁省',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/辽宁.png'
  },
  {
    name: '北京市',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/北京.png'
  },
  {
    name: '天津市',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/天津.png'
  },
  {
    name: '河北省',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/河北.png'
  },
  {
    name: '山西省',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/山西.png'
  },
  {
    name: '山东省',
    text: '渠道伙伴10+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/山东.png'
  },
  {
    name: '陕西省',
    text: '渠道伙伴10+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/陕西.png'
  },
  {
    name: '河南省',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/河南.png'
  },
  {
    name: '江苏省',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/江苏.png'
  },
  {
    name: '四川省',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/四川.png'
  },
  {
    name: '重庆市',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/重庆.png'
  },
  {
    name: '湖北省',
    text: '渠道伙伴30+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/湖北.png'
  },
  {
    name: '安徽省',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/安徽.png'
  },
  {
    name: '上海市',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/上海.png'
  },
  {
    name: '贵州省',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/贵州.png'
  },
  {
    name: '湖南省',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/湖南.png'
  },
  {
    name: '江西省',
    text: '渠道伙伴30+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/江西.png'
  },
  {
    name: '浙江省',
    text: '渠道伙伴30+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/浙江.png'
  },
  {
    name: '广西省',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/广西.png'
  },
  {
    name: '广东省',
    text: '渠道伙伴30+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/广东.png'
  },
  {
    name: '福建省',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/福建.png'
  },
  {
    name: '海南省',
    text: '渠道伙伴20+',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/海南.png'
  },
]

export default ChannelPart5_Data
