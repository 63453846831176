/**
 * @author  XuHongli
 * @date  2022/12/29 18:15
 * @version 1.0
 * @description
 */
const ChannelPart6_Data = [
  {
    title: '提交申请',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/sqlc_img_001.png'
  },
  {
    title: '审核资料',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/sqlc_img_002.png'
  },
  {
    title: '渠道洽谈',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/sqlc_img_003.png'
  },
  {
    title: '签约合作',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/sqlc_img_004.png'
  }
]

export default ChannelPart6_Data
