/**
 * @author  XuHongli
 * @date  2022/12/29 18:03
 * @version 1.0
 * @description
 */

const ChannelPart4_Data = [
  {
    title: '实力强大',
    desc: '百人技术团队，核心技术团队来自BAT',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/product_pic1.png'
  },
  {
    title: '品牌认可',
    desc: '超5000+知名品牌认可',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/product_pic2.png'
  },
  {
    title: '开放生态',
    desc: '开放企业生态，合作共赢',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/product_pic3.png'
  },
  {
    title: '研发创新',
    desc: '坚持自主创新，拥有核心技术',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/product_pic4.png'
  },
  {
    title: '管理支持',
    desc: '提供基于市场营销服务等各方面的标准化支持辅导',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/product_pic5.png'
  },
  {
    title: '资本信赖',
    desc: '与多家资本达成合作',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/product_pic6.png'
  }
]

export default ChannelPart4_Data
