<template>
  <div class="ChannelPart4">
    <div class="product_block">
      <div class="block_maintitle wow animate__zoomIn" data-wow-duration="1s">
        为什么选择万鲸
      </div>
    </div>
    <div class="product_content">
      <div
        v-for="(item, index) in ChannelPart4_Data"
        :key="index"
        :data-wow-delay="index * 0.2 + 's'"
        class="product_li wow animate__flipInX"
      >
        <div class="product_headline">
          <img :src="item.imgUrl" alt="" />
        </div>
        <div class="product_right">
          <div class="product_effect">{{ item.title }}</div>
          <div class="product_subtitle">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from "wowjs";
import ChannelPart4_Data from "@/views/Channel/Data/ChannelPart4_Data";

/**
 * @author  XuHongli
 * @date  2022/12/29 18:06
 * @version 1.0
 * @description
 */
export default {
  name: "ChannelPart4",
  data() {
    return {
      ChannelPart4_Data,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.ChannelPart4 {
  width: 100%;
  position: relative;
  padding: 80px 0 56px;
  background: #f6f8fa;
  .product_block {
    text-align: center;
  }
}
.product_content {
  width: 1280px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px auto auto;
  .product_li {
    width: 400px;
    height: 340px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 34px;
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
    .product_headline {
      overflow: hidden;
      img {
        width: 400px;
        height: 220px;
        transition: all 0.3s ease-out;
      }
    }
    .product_right {
      padding-left: 20px;
      background: #fff;
      flex: 1;
      .product_effect {
        font-size: 22px;
        font-weight: 400;
        color: #222;
        margin-top: 34px;
      }
      .product_subtitle {
        font-size: 18px;
        font-weight: 400;
        color: #666;
        margin-top: 8px;
      }
    }
  }
}
</style>
