/**
 * @author  XuHongli
 * @date  2022/12/29 16:57
 * @version 1.0
 * @description
 */
const ChannelPart2_Data = [
  {
    title: '高比率分佣分成',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/ensure_swzl.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/frcl_img_0001.png'
  },
  {
    title: '阶梯式高佣补贴',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/frcl_imgs_002.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/frcl_img_0002.png'
  },
  {
    title: '丰厚年奖金',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/ensure_qyyx.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/frcl_img_0003.png'
  },
  {
    title: '代理商创业补贴',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/frcl_imgs_004.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/frcl_img_0004.png'
  },
  {
    title: '运营服务分成',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/dcj_img_001.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/frcl_img_0005.png'
  },
  {
    title: '续约收益分成',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/frcl_imgs_006.png',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/frcl_img_0006.png'
  }
]

export default ChannelPart2_Data
