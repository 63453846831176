<template>
  <div class="ChannelPart6">
    <div class="process_block">
      <div class="block_maintitle">申请流程</div>
    </div>

    <div class="process_content">
      <div
        v-for="(item, index) in ChannelPart6_Data"
        :key="index"
        :style="{
          backgroundImage: `url(${
            $StaticFileUrl + '/mp/pages/Channel/square3.png'
          })`,
        }"
        data-wow-duration="1s"
        class="process_li animate__backInUp wow"
      >
        <img :src="item.imgUrl" alt="" />
        <div class="process_li_title">{{ item.title }}</div>
        <div class="process_li_item">
          <a href="javascript:void(0)">
            查看详情
            <img :src="$StaticFileUrl + '/mp/pages/Channel/red_right.png'" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChannelPart6_Data from "@/views/Channel/Data/ChannelPart6_Data";
import WOW from "wowjs";

/**
 * @author  XuHongli
 * @date  2022/12/29 18:18
 * @version 1.0
 * @description
 */
export default {
  name: "ChannelPart6",
  data() {
    return {
      ChannelPart6_Data,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.ChannelPart6 {
  background: #f6f8fa;
  padding: 80px 0 140px;
  position: relative;
  .process_block {
    text-align: center;
  }
}
.process_content {
  padding-top: 70px;
  display: flex;
  align-items: center;
  width: 1280px;
  margin: auto;
  .process_li {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 340px;
    height: 166px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-right: -20px;
    transition: all 0.5s;
    &:hover {
      > img {
        opacity: 0;
        transform: translateY(-60px);
      }
      .process_li_title {
        transform: translateY(-60px);
      }
      .process_li_item {
        opacity: 1;
        bottom: 30px;
      }
    }
    img {
      width: 108px;
      height: 98px;
      transition: all 0.3s;
    }
    div {
      font-size: 18px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei, serif;
      font-weight: 700;
      color: #222;
      line-height: 26px;
      margin-top: 18px;
      margin-bottom: 18px;
      transition: all 0.5s;
    }
    .process_li_item {
      position: absolute;
      bottom: 0;
      opacity: 0;
      transition: all 0.5s;
      a {
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
        font-weight: 400;
        color: #ff6a00;
        line-height: 32px;
        cursor: pointer;
        padding-left: 20px;
      }
      img {
        width: 18px;
        height: 12px;
        margin-left: 9px;
      }
    }
  }
}
</style>
