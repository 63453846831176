/**
 * @author  XuHongli
 * @date  2022/12/29 17:50
 * @version 1.0
 * @description
 */

const ChannelPart3_Data = [
  {
    title: '商机支持',
    desc: '提供销售线索，助力代理商快速提升销售业绩',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/material_opportunity.png'
  },
  {
    title: '培训支持',
    desc: '组织开展产品、市场、运营等运营培训，提升代理商工作能力',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/material_train.png'
  },
  {
    title: '市场活动支持',
    desc: '组织开展线上线下活动，多形势助力拉新促活',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/material_market.png'
  },
  {
    title: '运营服务支持',
    desc: '提供运营解决方案，满足不同行业客户需求',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/Channel/material_career.png'
  }
]

export default ChannelPart3_Data
