<template>
  <div class="ChannelPart1">
    <img class="rightImg" style="left: 0;top: 11%;" v-lazy="$StaticFileUrl + '/mp/img/blief_bag.png'" alt=""/>
    <div class="core_block wow animate__fadeInUp" data-wow-duration=".5s">选择万鲸 实现千万营收</div>
    <div class="channel-box" :style="{backgroundImage: `url(${$StaticFileUrl + '/mp/pages/Channel/xz_bj_002.png'})` }">
      <div class="channel_img wow animate__fadeInUp" data-wow-duration="1s">
        <img :src="$StaticFileUrl + '/mp/pages/Channel/xzdby_img_001.png'" alt="">
      </div>
      <div class="core_content">
        <div class="core_li" v-for="(item,index) in ChannelPart1_Data" :key="index">
          <div class="core_headline">
            <img v-lazy="$StaticFileUrl + '/mp/pages/Channel/channel_circle.png'" alt="">
            <div class="core_desc">{{ item.desc }}</div>
          </div>
          <div class="core_title_subtitle">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChannelPart1_Data from '@/views/Channel/Data/ChannelPart1_Data'
import WOW from 'wowjs'

/**
 * @author  XuHongli
 * @date  2022/12/29 16:18
 * @version 1.0
 * @description
 */
export default {
  name: 'ChannelPart1',
  data() {
    return {
      ChannelPart1_Data
    }
  },
  mounted(){
    new WOW.WOW().init()
  }
}
</script>

<style scoped lang="scss">
.ChannelPart1{
  height: 634px;
  padding: 72px 0 0;
  width: 100%;
  position: relative;
  background: #fff no-repeat;
  background-size: 100% 100%;
  .core_block{
    text-align: center;
    margin-bottom: 60px!important;
    font-size: 40px;
    font-family: Microsoft YaHei-Regular,Microsoft YaHei,serif;
    font-weight: 400;
    color: #333;
  }
  .channel-box{
    position: relative;
    height: 450px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .channel_img{
      position: absolute;
      left: 25.5%;
      top: -50px;
      z-index: 2;
      width: 512px;
      height: 512px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .core_content{
      position: absolute;
      width: 450px;
      left: 61%;
      height: 450px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .core_li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 44px;
        position: relative;
        .core_headline{
          display: flex;
          align-items: center;
          img{
            width: 30px;
            height: 24px;
            margin-right: 17px;
          }
          .core_desc{
            font-size: 28px;
            font-family: Microsoft YaHei,serif;
            font-weight: 700;
            color: #222;
          }
        }
        .core_title_subtitle{
          width: 200px;
          text-align: left;
          font-size: 20px;
          font-weight: 400;
          color: #222;
        }
      }
    }
  }
}
</style>
