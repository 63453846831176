<template>
  <div class="ChannelPart2">
    <div class="ensure_block wow animate__slideInUp" data-wow-duration="1s">
      <div class="block_maintitle">分润策略</div>
    </div>
    <div class="ensure_content">
      <div
        class="bg1"
        :style="{
          backgroundImage: `url(${
            $StaticFileUrl + '/mp/pages/Channel/frcl_img_001.png'
          })`,
        }"
      ></div>
      <div
        class="bg2"
        :style="{
          backgroundImage: `url(${
            $StaticFileUrl + '/mp/pages/Channel/frcl_img_001.png'
          })`,
        }"
      ></div>
      <div class="ensure_left">
        <div
          v-for="(item, index) in ChannelPart2_Data"
          :key="index"
          :class="[
            'ensure_li',
            'wow',
            { animate__fadeInLeft: index === 0 },
            { animate__fadeInRight: index !== 0 },
          ]"
          data-wow-duration="1s"
          @mouseenter="activeKey = index"
        >
          <div :class="['ensure_li_position', { active: activeKey === index }]">
            <img :src="item.icon" alt="" />
            <div class="ensure_headline">高比率分佣分成</div>
            <span class="bar"></span>
          </div>
        </div>
      </div>
      <div class="ensure_right wow animate__fadeInUp" data-wow-duration="1s">
        <img
          :src="item.imgUrl"
          :class="{ active: activeKey === index }"
          v-for="(item, index) in ChannelPart2_Data"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChannelPart2_Data from "@/views/Channel/Data/ChannelPart2_Data";

/**
 * @author  XuHongli
 * @date  2022/12/29 16:56
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
export default {
  name: "ChannelPart2",
  data() {
    return {
      ChannelPart2_Data,
      activeKey: 0,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.ChannelPart2 {
  width: 100%;
  background: #f6f8fa;
  padding: 60px 0;
  .ensure_block {
    width: 100%;
    margin-bottom: 56px;
    text-align: center;
  }
}
.ensure_content {
  position: relative;
  padding-right: 200px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  .bg1 {
    position: absolute;
    top: -110px;
    left: -105px;
    width: 271px;
    height: 276px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bg2 {
    position: absolute;
    bottom: -15px;
    right: -70px;
    width: 271px;
    height: 276px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .ensure_left {
    display: flex;
    flex-wrap: wrap;
    width: 354px;
    margin-right: 10px;
    .ensure_li {
      width: 161px;
      height: 179px;
      margin-bottom: 18px;
      margin-right: 15px;
      &:nth-child(5) {
        position: absolute;
        top: 0;
        right: 45px;
      }
      &:nth-child(6) {
        position: absolute;
        bottom: 0;
        right: 45px;
      }
      .bar {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 4px;
        background-color: #fc0017;
        width: 0;
        transition: all 0.4s ease;
      }
      .ensure_li_position {
        width: 100%;
        height: 100%;
        background: #fff;
        box-shadow: 0 3px 21px 1px rgba(0, 0, 0, 0.08);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        &.active {
          transform: translateY(-5px) !important;
          .bar {
            width: 100%;
          }
          .ensure_headline {
            font-weight: 700;
            color: #222;
          }
        }
        img {
          width: 30px;
          height: 30px;
        }
        .ensure_headline {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
          font-weight: 400;
          color: #333;
          margin-top: 17px;
        }
      }
    }
  }
  .ensure_right {
    width: 618px;
    height: 372px;
    background-color: #fff;
    box-shadow: 0 3px 21px 1px rgba(0, 0, 0, 0.08);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    overflow: hidden;
    > img {
      &.active {
        transform: scale(1.25);
        opacity: 1;
        z-index: 3;
      }
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.6s ease;
      transform: scale(1.6);
    }
  }
}
</style>
